import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/NoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "aoc-2020--getting-started-with-unison",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#aoc-2020--getting-started-with-unison",
        "aria-label": "aoc 2020  getting started with unison permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`AoC 2020 — Getting Started with Unison`}</h1>
    <p>{`I’m starting Advent of Code late, but it’s okay. I don’t care about the
leaderboard anyway, I just want to learn a few new languages.`}</p>
    <p>{`My fiancee already completed all puzzles published to this point, and if I
used a conventional language I’d have to try hard to get better asymptotic
time complexity than her to save my male pride.`}</p>
    <p>{`By using bleeding edge languages, I’m making it hard enough for myself, that
I won’t feel the need to compete with her.`}</p>
    <p>{`Hello Unison. Thank you for your help.`}</p>
    <p>{`Unison so new that you have to join #alphatesting channel on their Slack to
see the installation instructions.`}</p>
    <p>{`Unison Codebase Manager is an all-in-one tool for everything Unison. I love
it. The bundling here saves a lot of complexity. I liked that `}<inlineCode parentName="p">{`go fmt`}</inlineCode>{` is
built-in and belongs to the language, and I like Rust’s `}<inlineCode parentName="p">{`cargo`}</inlineCode>{` even more.
This is the way to go in 2020. Imagine there are people who write JavaScript
and don’t use Prettier. Scary, huh? Point for you, Unison.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`.> pull https://github.com/unisonweb/base:.releases._latest base`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`  Importing downloaded files into local codebase...`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`  Here's what's changed in base after the merge:`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`  Added definitions:`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    1.    ability Abort (+2 metadata)`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    2.    ability Ask a (+2 metadata)`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    3.    unique type Author`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    4.    builtin type Boolean`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    5.    builtin type Bytes`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    6.    builtin type Char`}</span></code></pre>
    <p>{`Nice one.`}</p>
    <p>{`There’s no VSCode Language Server extension for Unison, but it’s less
painful than I expected.`}</p>
    <p>{`I wrote my first bit of Unison…`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "u"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` List.map (x `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`->`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` x * 10) `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`[`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`1,2,3,4,5,6`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`]`}</span></span></code></pre>
    <p>{`And I immediately saw the result in my terminal.`}</p>
    <p>{`It’s a bit like `}<a parentName="p" {...{
        "href": "https://quokkajs.com/"
      }}>{`Quokka.js`}</a>{` for TypeScript, but built
into the language. Sweet. Of course, I’d prefer to see the result right next
to the code, as I write it, but I know they’re working on an editor of their
own, so this might be how it looks like in the future.`}</p>
    <p>{`The lack of autocomplete will be a bit painful. I’ll have to read the docs
in the browser of instead of `}<em parentName="p">{`“querying them`}</em>{`” from the editor.`}</p>
    <p>{`After a longer `}<a parentName="p" {...{
        "href": "https://www.unisonweb.org/docs/tour"
      }}>{`tour of Unison`}</a>{`, I
started with the first puzzle — `}<a parentName="p" {...{
        "href": "/notes/day-1-report-repair",
        "title": "Day 1 - Report Repair"
      }}>{`Day 1 - Report Repair`}</a>{`.`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .night-owl-no-italics {
background-color: #011627;
color: #d6deeb;
}

.night-owl-no-italics .mtk1 { color: #D6DEEB; }
.night-owl-no-italics .mtk2 { color: #011627; }
.night-owl-no-italics .mtk3 { color: #C792EA; }
.night-owl-no-italics .mtk4 { color: #82B1FF; }
.night-owl-no-italics .mtk5 { color: #ADDB67; }
.night-owl-no-italics .mtk6 { color: #637777; }
.night-owl-no-italics .mtk7 { color: #82AAFF; }
.night-owl-no-italics .mtk8 { color: #F78C6C; }
.night-owl-no-italics .mtk9 { color: #7FDBCA; }
.night-owl-no-italics .mtk10 { color: #FF5874; }
.night-owl-no-italics .mtk11 { color: #FFEB95; }
.night-owl-no-italics .mtk12 { color: #FFCB8B; }
.night-owl-no-italics .mtk13 { color: #7FDBCAFF; }
.night-owl-no-italics .mtk14 { color: #80CBC4; }
.night-owl-no-italics .mtk15 { color: #FF6363; }
.night-owl-no-italics .mtk16 { color: #57EAF1; }
.night-owl-no-italics .mtk17 { color: #5F7E97; }
.night-owl-no-italics .mtk18 { color: #ECC48D; }
.night-owl-no-italics .mtk19 { color: #B2CCD6; }
.night-owl-no-italics .mtk20 { color: #FAD430; }
.night-owl-no-italics .mtk21 { color: #FFFFFF; }
.night-owl-no-italics .mtk22 { color: #FF2C83; }
.night-owl-no-italics .mtk23 { color: #020E14; }
.night-owl-no-italics .mtk24 { color: #D3423E; }
.night-owl-no-italics .mtk25 { color: #EC5F67; }
.night-owl-no-italics .mtk26 { color: #8BD649; }
.night-owl-no-italics .mtk27 { color: #5CA7E4; }
.night-owl-no-italics .mtk28 { color: #A2BFFC; }
.night-owl-no-italics .mtk29 { color: #EF535090; }
.night-owl-no-italics .mtk30 { color: #ADDB67FF; }
.night-owl-no-italics .mtk31 { color: #697098; }
.night-owl-no-italics .mtk32 { color: #FF869A; }
.night-owl-no-italics .mtk33 { color: #DDDDDD; }
.night-owl-no-italics .mtk34 { color: #E0DEC6; }
.night-owl-no-italics .mtk35 { color: #CDEBF7; }
.night-owl-no-italics .mtk36 { color: #D9F5DD; }
.night-owl-no-italics .mtk37 { color: #6AE9F0; }
.night-owl-no-italics .mtk38 { color: #C789D6; }
.night-owl-no-italics .mtk39 { color: #BEC5D4; }
.night-owl-no-italics .mtk40 { color: #31E1EB; }
.night-owl-no-italics .mtk41 { color: #8EACE3; }
.night-owl-no-italics .mtk42 { color: #78CCF0; }
.night-owl-no-italics .mtk43 { color: #FAF39F; }
.night-owl-no-italics .mtk44 { color: #D7DBE0; }
.night-owl-no-italics .mtk45 { color: #7986E7; }
.night-owl-no-italics .mtki { font-style: italic; }
.night-owl-no-italics .mtkb { font-weight: bold; }
.night-owl-no-italics .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      